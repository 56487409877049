<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>{{$t('instagram.priceDollars')}} </b-card-title>
      <b-card-sub-title class="text-secondary pt-1 font">{{$t('instagram.averageData')}}</b-card-sub-title>
    </b-card-header>
    <b-card-body class="statistics-body p-0">
      <b-row class="justify-content-around" v-if="priceData">
            <b-col lg="2" md="3" sm="4" v-for="(item, index) in priceData.data" :key="index" >
              <statistic-card-horizontal
                icon="VideoIcon"
                :statistic="byComma(item)"
                :statistic-title="$t(`profile.youtube.${index}`)"
                color="danger"
              />
            </b-col>
          </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    StatisticCardHorizontal: () => import('@core/components/statistics-cards/StatisticCardHorizontal.vue'),
  },
  props: {
    priceData: {
      type: Object,
      required: true
    }
  },
  methods: {
    byComma(num) {
      return `$${Intl.NumberFormat('en-US').format(num)}`
    }
  }
}
</script>
